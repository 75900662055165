import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstName',
})
export class FirstNamePipe implements PipeTransform {
  transform(name: string): string {
    if (!name) {
      return '';
    }
    const split = name.split(' ');
    return split[0];
  }
}
