import { IImageFile } from '@partake/interfaces';
import { ProfileType, ProfileVisibility } from '../../enums';
import { ISchema, SchemaType, Validators } from '../../schemas';
import { BaseData } from '../base';

export const ProfileSchema: ISchema = {
  name: {
    section: 'basic',
    label: 'Name Your Group',
    type: SchemaType.STRING,
    required: true,
    maxlength: 25,
    validators: [Validators.maxLength(25)],
    validationErrorMessage: 'Username should be max 25 characters.',
  },
  slug: {
    section: 'basic',
    label: 'Group Username',
    type: SchemaType.STRING,
    required: true,
    maxlength: 25,
    validators: [Validators.pattern(/[a-zA-Z0-9._]+/i), Validators.maxLength(25)],
    validationErrorMessage:
      'Username should be max 25 characters and contain numbers, letters or the special characters ._',
  },
  description: {
    section: 'basic',
    label: 'Welcome Message',
    type: SchemaType.LONG_STRING,
    maxlength: 1000,
    validators: [Validators.maxLength(1000)],
    validationErrorMessage: 'Username should be max 100 characters.',
    default:
      'Welcome to our official Partake group! Share your opinions, meets fans & discuss live games with us.',
  },
  type: {
    section: 'basic',
    label: 'Type',
    type: SchemaType.STRING,
    default: ProfileType.GROUP,
    immutable: true,
    isHidden: true,
  },
  visibility: {
    section: 'basic',
    label: 'Group Visibility',
    type: SchemaType.BOOLEAN,
    default: ProfileVisibility.PUBLIC,
    required: true,
    isHidden: true,
  },
  profileImage: {
    section: 'basic',
    label: 'Group Image',
    type: SchemaType.OBJECT,
    isHidden: true,
  },
  coverImage: {
    section: 'basic',
    label: 'Cover Image',
    type: SchemaType.OBJECT,
    isHidden: true,
  },
};

export interface Profile extends BaseData {
  id: number;
  slug: string;
  type: ProfileType;
  visibility: ProfileVisibility;
  name: string;
  description: string;
  profileImage: IImageFile; // ImageFile
  coverImage: IImageFile; // ImageFile
  memberCount: number;
  memberRequestCount: number;
  admins: string[]; // User['_id][]
  appId: string; // App['_id']
}
