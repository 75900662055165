/* eslint-disable no-param-reassign */
import _ from 'lodash';
import {
  DiningOptionsOrderType,
  DiscountType,
  OrderSource,
  OrderStatus,
  ServiceChargeBehavior,
} from '../enums';
import {
  OrderData,
  IOrderDestination,
  OrderedItem,
  OrderedItemDiscountVoucher,
  OrderedItemTax,
  DiscountData,
  OrderedItemModifier,
  TaxCodeData,
  CompItemData,
} from '../interfaces';
import {
  DiningOption,
  LocationData,
  MenuItem,
  MenuItemData,
  ModifierGroup,
  Order,
  ServiceChargeData,
} from '../models';
import TaxCalculator from './tax-calculator';

export default class OrderHelper {
  private activeDiningOption: DiningOption;

  constructor(
    private menuItemById: Record<string, MenuItem>,
    private taxesById: Record<string, any>,
    private serviceCharges: ServiceChargeData[],
    private modifierGroupsById: Record<string, ModifierGroup>
  ) {}

  public static nameFromStatus(status: OrderStatus): string {
    switch (status) {
      case OrderStatus.POINT_OF_SALE:
        return 'tab';
      default:
        return status;
    }
  }

  public static nameFromOrderType(orderType: DiningOptionsOrderType): string {
    switch (orderType) {
      case DiningOptionsOrderType.FUTURE:
        return 'Future';
      default:
        return 'ASAP';
    }
  }

  public updateMenuItemsById(menuItemsById: Record<string, any>) {
    this.menuItemById = menuItemsById;
  }

  public setActiveDiningOption(diningOption: DiningOption) {
    this.activeDiningOption = diningOption;
  }

  public canAdd(item: OrderedItem): boolean {
    const menuItem = _.get(this.menuItemById, item.item) || { data: {} as MenuItemData };
    const { availableQuantity } = menuItem.data;
    if (availableQuantity < 0) {
      return true;
    }
    if (availableQuantity >= item.quantity) {
      return true;
    }
    return false;
  }

  private getTaxesFromOrderedItem(orderedItem: OrderedItem): TaxCodeData[] {
    const menuItem = _.get(this.menuItemById, orderedItem.item) || { data: { taxCodes: [] } };
    return menuItem.data.taxCodes.map((taxId: string) => {
      return this.taxesById[taxId];
    });
  }

  public setItemTaxes(orderedItem: OrderedItem) {
    const itemDiscountTotal = orderedItem.discountTotal;
    const menuItem = _.get(this.menuItemById, orderedItem.item) || { data: { taxCodes: [] } };
    const { unitPrice, quantity } = orderedItem;
    const itemPrice = unitPrice * quantity - itemDiscountTotal;
    orderedItem.taxes = menuItem.data.taxCodes.map((tax: any) => {
      const rate = (tax?.taxRate || 0) * 0.01;
      const total = Math.ceil(rate * itemPrice);
      return {
        id: tax._id,
        total,
      };
    });
    orderedItem.itemTaxTotal = orderedItem.taxes.reduce((prev: number, curr: OrderedItemTax) => {
      return prev + curr.total;
    }, 0);
  }

  public setTaxes(orderedItem: OrderedItem) {
    const taxCodes = this.getTaxesFromOrderedItem(orderedItem);
    TaxCalculator.setTaxesForOrderedItem(orderedItem, taxCodes);
    TaxCalculator.setTaxesForOrderedItemModifiers(
      orderedItem,
      this.taxesById,
      this.modifierGroupsById
    );
    orderedItem.taxTotal = orderedItem.itemTaxTotal + orderedItem.modifierTaxTotal;
    orderedItem.taxIncludedTaxTotal = orderedItem.taxIncluded ? orderedItem.itemTaxTotal : 0;
    orderedItem.taxIncludedTaxTotal += orderedItem.modifiers.reduce(
      (taxIncludedTotal, modifier) => {
        if (modifier.taxIncluded) {
          taxIncludedTotal += modifier.taxTotal;
        }
        return taxIncludedTotal;
      },
      0
    );
  }

  public getItemTaxes(orderedItem: OrderedItem, taxes: Array<any>): OrderedItemTax[] {
    const itemDiscountTotal = orderedItem.discountTotal;
    const { unitPrice, quantity } = orderedItem;
    const itemPrice = unitPrice * quantity - itemDiscountTotal;
    return taxes.map((taxId: string) => {
      const tax = this.taxesById[taxId];
      const taxRate = (tax?.taxRate || 0) * 0.01;
      let total = 0;
      if (orderedItem.taxIncluded) {
        total = Math.ceil((itemPrice * taxRate) / (1 + taxRate));
      } else {
        total = Math.ceil(taxRate * itemPrice);
      }
      return {
        id: tax._id,
        total,
        ptTotal: 0,
      };
    });
  }

  public setModifierTaxes(orderedItem: OrderedItem): number {
    orderedItem.modifiers.forEach(modifier => {
      const modifierGroup = this.modifierGroupsById[modifier.modifierSet];
      const modifierOption = modifierGroup?.data.options.find(
        option => option._id === modifier.modifierSetOption
      );
      const modifierTaxes = modifierOption.taxCodes.map(taxId => {
        const tax = this.taxesById[taxId];
        const taxRate = (tax?.taxRate || 0) * 0.01;
        let total = 0;
        const { price, quantity } = modifier;
        const modifierPrice = price * quantity;
        if (modifier.taxIncluded) {
          total = Math.ceil((modifierPrice * taxRate) / (1 + taxRate));
        } else {
          total = Math.ceil(taxRate * modifierPrice);
        }
        return {
          id: tax._id,
          total,
          ptTotal: 0,
        };
      });
      modifier.taxes = modifierTaxes;
      const modifierTaxTotal = modifierTaxes.reduce((prev: number, curr: OrderedItemTax) => {
        return prev + curr.total;
      }, 0);
      modifier.taxTotal = modifierTaxTotal;
    });
    return orderedItem.modifiers.reduce((prev, curr) => {
      return prev + curr.taxTotal;
    }, 0);
  }

  public getTaxes(item: OrderedItem) {
    const menuItem = _.get(this.menuItemById, item.item) || { data: { taxCodes: [] } };
    const itemTaxes = this.getItemTaxes(item, menuItem.data.taxCodes);
    const itemTaxTotal = itemTaxes.reduce((prev: number, curr: OrderedItemTax) => {
      return prev + curr.total;
    }, 0);
    const modifierTaxTotal = this.setModifierTaxes(item);
    return {
      itemTaxes,
      itemTaxTotal,
      modifierTaxTotal,
    };
  }

  public canUseDiscount(item: OrderedItem['item'], discount: DiscountData): boolean {
    const menuItem = _.get(this.menuItemById, item);
    if (!_.size(menuItem?.data.discounts)) {
      return false;
    }
    return _.reduce(
      menuItem?.data.discounts,
      (prev: boolean, curr: DiscountData) => {
        if (discount._id === curr._id) {
          return true;
        }
        return prev;
      },
      false
    );
  }

  private getItemDiscountTotal(item: OrderedItem): number {
    const itemDiscountTotal = item.discounts?.reduce(
      (currTotal: number, itemDiscount: OrderedItemDiscountVoucher) => {
        return currTotal + itemDiscount.total || 0;
      },
      0
    );
    const modifierDiscountTotal = item.modifiers?.reduce(
      (modDiscountTotal: number, modifier: OrderedItemModifier) => {
        return (
          modDiscountTotal +
          modifier.discounts?.reduce(
            (currTotal: number, modifierDiscount: OrderedItemDiscountVoucher) => {
              return currTotal + modifierDiscount.total || 0;
            },
            0
          )
        );
      },
      0
    );
    return itemDiscountTotal + modifierDiscountTotal;
  }

  public calculateItemDiscount(item: OrderedItem, order: Order, discount: DiscountData): number {
    switch (discount.type) {
      case DiscountType.FIXED_AMOUNT: {
        const total = order.data.items.reduce((prev: number, curr: OrderedItem) => {
          return prev + this.getItemDiscountTotal(curr);
        }, 0);
        if (item.unitPrice * item.quantity + total <= discount.value) {
          return item.unitPrice * item.quantity;
        }
        return discount.value - total;
      }
      case DiscountType.PERCENT:
        return Math.ceil(item.unitPrice * item.quantity * (discount.value * 0.01));
      case DiscountType.COMPLIMENTARY:
        return item.unitPrice;
      default:
        return 0;
    }
  }

  public calculateModifierDiscount(
    modifier: OrderedItemModifier,
    order: Order,
    discount: DiscountData,
    itemQuantity: number
  ): number {
    switch (discount.type) {
      case DiscountType.FIXED_AMOUNT: {
        const total = order.data.items.reduce((prev: number, curr: OrderedItem) => {
          return prev + this.getItemDiscountTotal(curr);
        }, 0);
        if (modifier.unitPrice * modifier.quantity * itemQuantity + total <= discount.value) {
          return modifier.unitPrice * modifier.quantity * itemQuantity;
        }
        return discount.value - total;
      }
      case DiscountType.PERCENT:
        return Math.ceil(
          modifier.unitPrice * modifier.quantity * itemQuantity * (discount.value * 0.01)
        );
      case DiscountType.COMPLIMENTARY:
        return modifier.unitPrice;
      default:
        return 0;
    }
  }

  public setDiscount(order: Order, discount: DiscountData): any {
    order.data.items.forEach((item: OrderedItem) => {
      item.discounts = [];
      item.itemDiscountTotal = 0;
      item.modifierDiscountTotal = 0;
      item.discountTotal = 0;
      item.modifiers.forEach(modifier => {
        modifier.discounts = [];
        modifier.discountTotal = 0;
      });
      if (this.canUseDiscount(item.item, discount)) {
        const itemDiscountValue = this.calculateItemDiscount(item, order, discount);
        item.itemDiscountTotal = itemDiscountValue;
        item.discounts.push({
          id: discount._id,
          total: item.itemDiscountTotal,
          type: discount.type,
        });
        item.modifiers.forEach(modifier => {
          const modififierDiscountValue = this.calculateModifierDiscount(
            modifier,
            order,
            discount,
            item.quantity
          );
          modifier.discountTotal = modififierDiscountValue;
          modifier.discounts.push({
            id: discount._id,
            total: modifier.discountTotal,
            type: discount.type,
          });
        });
        item.modifierDiscountTotal = item.modifiers.reduce(
          (prev: number, curr: OrderedItemModifier) => {
            return prev + curr.discountTotal;
          },
          0
        );
        item.discountTotal = item.itemDiscountTotal + item.modifierDiscountTotal;
        this.setTaxes(item);
      }
    });
  }

  public setCompDiscount(order: Order, discount: DiscountData, compItems: CompItemData[]): any {
    order.data.items.forEach((item: OrderedItem, i: number) => {
      if (compItems[i].compQuantity && this.canUseDiscount(item.item, discount)) {
        item.discounts = [];
        const itemDiscountValue = this.calculateItemDiscount(item, order, discount);
        item.itemDiscountTotal = itemDiscountValue * compItems[i].compQuantity;
        item.discounts.push({
          id: discount._id,
          total: item.itemDiscountTotal,
          type: discount.type,
        });
        item.modifiers.forEach(modifier => {
          const modififierDiscountValue = this.calculateModifierDiscount(
            modifier,
            order,
            discount,
            item.quantity
          );
          modifier.discountTotal = modififierDiscountValue * compItems[i].compQuantity;
          modifier.discounts.push({
            id: discount._id,
            total: modifier.discountTotal,
            type: discount.type,
          });
        });
        item.modifierDiscountTotal = item.modifiers.reduce(
          (prev: number, curr: OrderedItemModifier) => {
            return prev + curr.discountTotal;
          },
          0
        );
        item.discountTotal = item.itemDiscountTotal + item.modifierDiscountTotal;
        this.setTaxes(item);
      }
    });
  }

  /**
   * @todo TransactionData
   */
  public updateGratuityWithPercentage(transaction: any, gratuity: any) {
    const gratValue = gratuity.value * 0.01;
    transaction.gratuity = Math.ceil((transaction.total - transaction.gratuity) * gratValue);
    transaction.amount =
      transaction.subTotal - transaction.discount + transaction.taxTotal + transaction.serviceFee;
    transaction.total = transaction.amount + transaction.gratuity;
    return transaction;
  }

  /**
   * @todo TransactionData
   */
  public updateGratuityWithAmount(transaction: any, amount: number) {
    transaction.gratuity = amount;
    transaction.amount =
      transaction.subTotal - transaction.discount + transaction.taxTotal + transaction.serviceFee;
    transaction.total = transaction.amount + transaction.gratuity;
    return transaction;
  }

  public static getServiceChargeAmount(order: Order, serviceCharge: ServiceChargeData): number {
    switch (serviceCharge.behavior) {
      case ServiceChargeBehavior.FIXED_AMOUNT:
        return serviceCharge.value;
      case ServiceChargeBehavior.PERCENTAGE_ON_SUB_TOTAL:
        return Math.ceil(order.data.subTotal * serviceCharge.value);
      case ServiceChargeBehavior.PERCENTAGE_ON_TOTAL:
        return Math.ceil(order.data.total * serviceCharge.value);
      case ServiceChargeBehavior.PERCENTAGE_PRE_TAX:
        return Math.ceil((order.data.subTotal - order.data.discount) * serviceCharge.value);
      default:
        return 0;
    }
  }

  public applyServiceCharges(order: Order) {
    const serviceChargeIds = this.activeDiningOption.data.serviceCharges;
    const serviceChargesById = _.keyBy(this.serviceCharges, '_id');
    const orderServiceCharges: any[] = [];
    let serviceChargeTotal = 0;
    let partakeServiceChargeTotal = 0;
    serviceChargeIds?.forEach((serviceChargeId: ServiceChargeData['_id']) => {
      const serviceCharge = serviceChargesById[serviceChargeId];
      if (!serviceCharge) {
        return;
      }
      const orderServiceCharge = {
        name: serviceCharge.name,
        amount: OrderHelper.getServiceChargeAmount(order, serviceCharge),
        serviceChargeId: serviceCharge._id,
        isPartake: serviceCharge.isPartake,
        isTenderCharge: serviceCharge.isTenderCharge,
      };
      switch (serviceCharge.behavior) {
        case ServiceChargeBehavior.FIXED_AMOUNT:
          orderServiceCharge.amount = serviceCharge.value;
          break;
        case ServiceChargeBehavior.PERCENTAGE_ON_SUB_TOTAL:
          orderServiceCharge.amount = Math.ceil(order.data.subTotal * serviceCharge.value);
          break;
        case ServiceChargeBehavior.PERCENTAGE_ON_TOTAL:
          orderServiceCharge.amount = Math.ceil(order.data.total * serviceCharge.value);
          break;
        case ServiceChargeBehavior.PERCENTAGE_PRE_TAX:
          orderServiceCharge.amount = Math.ceil(
            (order.data.subTotal - order.data.discount) * serviceCharge.value
          );
          break;
        default:
          break;
      }
      orderServiceCharges.push(orderServiceCharge);
      serviceChargeTotal += orderServiceCharge.amount;
      if (orderServiceCharge.isPartake) {
        partakeServiceChargeTotal += orderServiceCharge.amount;
      }
    });
    order.data.serviceCharges = orderServiceCharges;
    order.data.serviceFee = serviceChargeTotal;
    order.data.partakeServiceFee = partakeServiceChargeTotal;
  }

  public updateOrderDestination(
    order: Order,
    diningOption: DiningOption,
    orderDestination: IOrderDestination
  ) {
    this.activeDiningOption = diningOption;
    order.data.orderDestination = orderDestination;
    this.applyServiceCharges(order);
  }

  public defaultCart(
    restaurant: LocationData,
    orderDestination: IOrderDestination,
    diningOption: DiningOption
  ): Order {
    this.activeDiningOption = diningOption;
    const order = new Order({
      venue: (restaurant.venue as any)?._id || restaurant.venue,
      restaurant: restaurant._id,
      isConsumerOrder: true,
      status: OrderStatus.CART,
      source: OrderSource.CUSTOM_WEB, // Make based on BUILD_SETTING
      orderDestination,
      user: null,
      guest: null,
      items: [],
      removed: [],
      void: [],
      subTotal: 0,
      discount: 0,
      taxTotal: 0,
      taxIncludedTaxTotal: 0,
      partakeTax: 0,
      gratuity: 0,
      serviceFee: 0,
      serviceCharges: [],
      roundUpTotal: 0,
      total: 0,
      amountPaid: 0,
      due: 0,
      alcoholCount: 0,
      ageNotified: false,
      shouldRoundUp: false,
      isFirstOrder: false,
      redeemReward: false,
      tags: [],
    } as OrderData);
    this.applyServiceCharges(order);
    return order;
  }
}
