import { Component, Input, OnInit } from '@angular/core';
import { ICustomerEducation } from '../../../models';

@Component({
  selector: 'app-generic-education-modal',
  templateUrl: './generic-education-modal.component.html',
  styleUrls: ['./generic-education-modal.component.scss'],
})
export class GenericEducationModalComponent implements OnInit {
  @Input() education: ICustomerEducation;

  constructor() {}

  ngOnInit() {}
}
