import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ccBrand',
})
export class CCBrandPipe implements PipeTransform {
  transform(brand: string): string {
    let newBrand = brand;
    if (!brand) {
      return newBrand;
    }
    const lcBrand = brand.toLowerCase();

    switch (lcBrand) {
      case 'american express':
        newBrand = 'Amex';
        break;
      default:
        break;
    }
    return newBrand;
  }
}
