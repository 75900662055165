export enum VoucherDiscountType {
  UNIT = 'unit',
  AMOUNT = 'amount',
  PERCENTAGE = 'percentage',
}

export enum VoucherAppliedToType {
  ORDER = 'order',
  CATEGORY = 'category',
  ITEM = 'item',
}

export enum VoucherRedemptionPerUserLimit {
  LIFETIME = 'lifetime',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum VoucherType {
  DISCOUNT = 'discount_voucher',
  GIFT = 'gift_voucher',
  MEMBER_DISCOUNT = 'member-discount',
}
