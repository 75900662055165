import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gameMultiple',
})
export class GameMultiplePipe implements PipeTransform {
  transform(multiple: number): string {
    if (!multiple) {
      return '';
    }
    if (multiple % 1 !== 0) {
      return multiple.toFixed(1);
    }
    return multiple.toFixed(0);
  }
}
