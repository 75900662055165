import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import { environment } from '@env/environment';
import { ConfigService } from '../../config';
import { LocalStorage } from '../../helpers';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _http: HttpClient;
  private domain: string;
  private baseUrl: string;

  private authStatusListener = new Subject<boolean>();

  constructor(injector: Injector, config: ConfigService) {
    this._http = injector.get(HttpClient);
    this.domain = `${environment.urls.domains.api[config.ENV]}/${environment.urls.versions.v1}`;
    this.baseUrl = this.domain;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  signup(signup: any) {
    return this._http.post<any>(`${this.baseUrl}/signup`, signup);
  }

  login(login: any) {
    return this._http.post<any>(`${this.baseUrl}/signin`, login);
  }

  updatePassword(update: any) {
    return this._http.post<any>(`${this.baseUrl}/reset_password`, update);
  }

  requestReset(update: any) {
    return this._http.post<any>(`${this.baseUrl}/request_reset`, update);
  }

  // Save the auth token in local storage
  async setToken(token?: string) {
    if (token) {
      await LocalStorage.addItem('token', token);
      this.authStatusListener.next(true);
    } else {
      await LocalStorage.removeItem('token');
      this.authStatusListener.next(false);
    }
  }

  // Get token from local storage
  async getToken() {
    const token = await LocalStorage.getItem('token');
    return token;
  }

  /*
    Check if we are logged in
    - This is a cheap and dirty way of checking if we are logged in.
    - The backed needs to ensure that we are authorized access certain endpoints.
  */
  async isAuthenticated() {
    const token = await this.getToken();
    if (token) {
      return true;
    }
    return false;
  }
}
