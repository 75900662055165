import { Injectable } from '@angular/core';
import _ from 'lodash';
import moment from 'moment';
import { ApiClient } from '../../clients';
import { ApiQuery } from '../../clients/api-client.types';
import { ConfigService } from '../../config/config.service';
import { ApiDomain, ApiResources, ApiVersion, EducationType, IntroModule } from '../../enums';
import { ICustomerEducation } from '../../models';
import { UserService } from '../../providers/user/user.service';
import { EducationModalController } from './education-modal/education-modal.controller';
import { EducationModule } from './education.module';
import { GenericEducationModalController } from './generic-education-modal/generic-education-modal.controller';

export interface IEducationUpdatePayload {
  type: EducationType;
  module?: IntroModule;
}

@Injectable({
  providedIn: EducationModule,
})
export class EducationService {
  private user: any;
  private apiClient: ApiClient;

  private seriesByModule: { [key: string]: Array<ICustomerEducation> };
  private pages: { [key: string]: ICustomerEducation };

  constructor(
    private config: ConfigService,
    private modalCtrl: EducationModalController,
    private genericModalCtrl: GenericEducationModalController,
    private userService: UserService
  ) {
    this.apiClient = new ApiClient().setDomain(ApiDomain.API, ApiVersion.V2);
  }

  public getTitle(type: EducationType): string {
    switch (type) {
      case EducationType.FAQ:
        return 'FAQ';
      case EducationType.INTRO:
        return 'How To';
      case EducationType.VIDEO:
        return 'How to Video';
      case EducationType.PRIVACY_POLICY:
        return 'Privacy Policy';
      case EducationType.TERMS_CONDITIONS:
        return 'Terms and Conditions';
      default:
        return '';
    }
  }

  public getType(type: EducationType) {
    return _.get(this.pages, type);
  }

  private async updateUserSeen(payload: IEducationUpdatePayload) {
    try {
      this.user = await this.userService.getMe();
      if (!this.user) {
        return;
      }
      await this.apiClient.put(`${ApiResources.USER}/seen-education`, payload).toPromise();
    } catch (err) {
      console.log(err);
    }
  }

  public async loadCustomerEducation() {
    try {
      const query: ApiQuery = {
        type: EducationType.INTRO,
        appId: this.config.whiteLabelId,
      };
      const result = await this.apiClient.get(query, ApiResources.HELP).toPromise();
      const series = _.sortBy<ICustomerEducation>(_.get(result, 'data.data'), ['position']);
      this.seriesByModule = _.groupBy<ICustomerEducation>(series, 'module');
    } catch (err) {
      console.log(err);
    }
  }

  public async showEducationSeries(moduleId: IntroModule): Promise<boolean> {
    const series = _.get(this.seriesByModule, moduleId);
    if (_.size(series)) {
      const { next } = await this.modalCtrl.show({
        steps: series,
      });
      this.updateUserSeen({ type: EducationType.INTRO, module: moduleId });
      if (next) {
        return true;
      }
    }
    return false;
  }

  public getUnseenEducation(moduleId: IntroModule, lastSeen?: Date): Array<ICustomerEducation> {
    const series = _.get(this.seriesByModule, moduleId);
    if (_.size(series)) {
      if (!lastSeen) {
        return [...series];
      }
      const lastSeenString = moment(lastSeen).format('YYYY-MM-DD');
      return series.filter((value: ICustomerEducation) => {
        const d = moment(value.updatedAt).format('YYYY-MM-DD');
        if (d > lastSeenString) {
          return true;
        }
        return false;
      });
    }
    return [];
  }

  public async loadTermsAndPrivacy(): Promise<void> {
    try {
      const query: ApiQuery = {
        type: `${EducationType.TERMS_CONDITIONS},${EducationType.PRIVACY_POLICY}`,
        appId: this.config.whiteLabelId,
      };
      const result = await this.apiClient.get(query, ApiResources.HELP).toPromise();
      this.pages = _.keyBy<ICustomerEducation>(_.get(result, 'data.data'), 'type');
    } catch (err) {
      console.log(err);
    }
  }

  public async showType(
    type: EducationType.PRIVACY_POLICY | EducationType.TERMS_CONDITIONS
  ): Promise<void> {
    const data = this.getType(type);
    if (data) {
      await this.genericModalCtrl.show({
        title: this.getTitle(type),
        education: data,
      });
      this.updateUserSeen({ type });
    }
  }
}
