import _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';
import { IMatch } from '@partake/interfaces';
import { SportsDataHelper } from '@app/helpers/sports-data.helper';

@Pipe({
  name: 'soccerPeriod',
})
export class SoccerPeriodPipe implements PipeTransform {
  transform(match: IMatch): string {
    const periodId = match?.matchDetails?.periodId ? match.matchDetails.periodId : 16; // Default to Pre-Match
    if (_.includes(SportsDataHelper.SHOW_TIME, periodId)) {
      const matchLength = match.matchDetails.matchLengthMin;
      const normalTime = SportsDataHelper.periodNormalTime(periodId);
      if (matchLength > normalTime) {
        return `'${normalTime} +${matchLength - normalTime}`;
      }
      return `'${matchLength}`;
    }
    return SportsDataHelper.soccerPeriodIdToString(periodId);
  }
}
