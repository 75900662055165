import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutoFocusDirective } from './auto-focus.directive';
import { EventTrackerDirective } from './event-tracker.directive';

@NgModule({
  declarations: [AutoFocusDirective, EventTrackerDirective],
  exports: [AutoFocusDirective, EventTrackerDirective],
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DirectivesModule {}
