export enum TransactionState {
  NONE = 'none',
  AUTHORIZED = 'authorized',
  CAPTURED = 'captured',
  VOID = 'void',
  UNCAPTURED_VOID = 'uncaptured-void',
  DECLINED = 'declined',
}

export enum TransactionResult {
  NONE = 'none',
  APPROVED = 'approved',
  DECLINED = 'declined',
}

export enum TransactionType {
  SALE = 'sale',
  REFUND = 'refund',
  TRANSFER = 'transfer',
}

export enum TransactionGateway {
  NONE = 'none',
  STRIPE = 'stripe',
  PAY_PAL = 'pay-pal',
  PAYLOAD = 'payload',
}

export enum TransactionCardScheme {
  CASH = 'cash',
  OTHER = 'other',
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  MAESTRO = 'maestro',
  AMEX = 'amex',
  JCB = 'jcb',
  DINERS = 'diners',
  DISCOVER = 'discover',
  CONNECTED_ACCOUNT = 'connected-account',
}

export enum TransactionCardEntryMethod {
  NONE = 'none',
  UNKNOWN = 'unknown',
  SWIPE = 'swipe',
  CHIP = 'chip',
  CONTACTLESS = 'contactless',
  CARD_ON_FILE = 'card-on-file',
  MANUAL_ENTRY = 'manual-entry',
  APPLE_PAY = 'apple-pay',
  GOOGLE_PAY = 'google-pay',
  SAMSUNG_PAY = 'samsung-pay',
  PAY_PAL = 'paypal',
}
