import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoccerPeriodPipe } from './soccer-period.pipe';

@NgModule({
  declarations: [SoccerPeriodPipe],
  exports: [SoccerPeriodPipe],
  imports: [CommonModule],
  providers: [SoccerPeriodPipe],
})
export class SportsPipesModule {}
