import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { DirectivesModule } from '@app/directives/directives.module';
import { EducationModule } from '../support/education/education.module';
import { EducationService } from '../support/education/education.service';

import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { SignupModalComponent } from './modals/signup-modal/signup-modal.component';
import { LoginModalComponent } from './modals/login-modal/login-modal.component';
import { PhoneComponent } from './phone/phone.component';
import { PhoneModalComponent } from './modals/phone-modal/phone-modal.component';
import { CountryModalComponent } from './modals/country-modal/country-modal.component';
import { PhoneVerificationModalComponent } from './modals/phone-verification-modal/phone-verification-modal.component';

import { PasswordResetModalComponent } from './modals/password-reset-modal/password-reset-modal.component';
import { OtpModalComponent } from './modals/opt-modal/otp-modal.component';

@NgModule({
  declarations: [
    SignupComponent,
    LoginComponent,
    SignupModalComponent,
    LoginModalComponent,
    PhoneComponent,
    PhoneModalComponent,
    CountryModalComponent,
    PhoneVerificationModalComponent,
    PasswordResetModalComponent,
    OtpModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    EducationModule,
    DirectivesModule,
  ],
  providers: [EducationService],
})
export class AuthModule {}
