/* eslint-disable no-restricted-syntax */
/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { ConfigService } from './config.service';

export const ConfigServiceFactory = () => {
  // Create config
  const config = new ConfigService();

  // Read config variables from browser window
  const browserWindow = window || {};
  const browserWindowConfig = browserWindow['__config'] || {};

  // Assign config variables from browser window to config
  // In the current implementation, properties from config.js overwrite defaults from the ConfigService.
  // If needed, a deep merge can be performed here to merge properties instead of overwriting them.
  for (const key in browserWindowConfig) {
    // eslint-disable-next-line no-prototype-builtins
    if (browserWindowConfig.hasOwnProperty(key) && browserWindowConfig[key]) {
      config[key] = window['__config'][key];
    }
  }
  return config;
};

export const ConfigServiceProvider = {
  provide: ConfigService,
  useFactory: ConfigServiceFactory,
  deps: [],
};
