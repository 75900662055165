import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ICustomerEducation } from '../../../models';
import { EducationModule } from '../education.module';
import { EducationModalComponent } from './education-modal.component';

export interface EducationModalOption {
  steps: Array<ICustomerEducation>;
}

@Injectable({
  providedIn: EducationModule,
})
export class EducationModalController {
  constructor(private modalCtrl: ModalController) {}

  async show(options: EducationModalOption): Promise<any> {
    const modal = await this.modalCtrl.create({
      cssClass: 'pt-modal education-modal',
      component: EducationModalComponent,
      componentProps: options,
    });
    modal.present();
    const { data } = await modal.onDidDismiss();
    return data;
  }
}
