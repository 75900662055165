import _ from 'lodash';

export interface AbstractModelData {
  _id?: string;
  name?: string;
  displayName?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export class AbstractModel<Data extends AbstractModelData> {
  constructor(public data: Data) {}

  get id() {
    return this.get('_id');
  }

  get json() {
    return {
      ...this.data,
    };
  }

  public displayName() {
    if (this.data.displayName) {
      return this.data.displayName;
    }
    return this.data.name;
  }

  /** @deprecated */
  public get(key: string) {
    return _.get(this.data, key);
  }

  /** @deprecated */
  public set(key: string, val: any) {
    this.data[key] = val;
  }
}
