import _ from 'lodash';
import { ImageSize } from '../enums';

export class ImageHelper {
  public static getImage(entity: any, size: ImageSize, prop = 'images') {
    if (_.size(entity[prop])) {
      const foundImage = entity[prop].find(image => image.size === size);
      if (foundImage) {
        return foundImage.url;
      }
    }
    return entity.imageUrl;
  }
}
