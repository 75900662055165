import { AuthMethods } from '../enums';

export enum CustomPageStyle {
  BRANDED = 'branded',
  DEFAULT = 'default',
}

export enum AppModule {
  ACCOUNT = 'account',
  DISCOVER = 'discover',
  FAVORITES = 'favorites',
  GAMES = 'fantasy',
  LIVE = 'live',
  MENU = 'menu',
  ORDERING = 'orders',
  SEARCH = 'search',
  VENUES = 'venues',
}

export enum CustomStringKey {
  SIGNUP_TITLE = 'signup-title',
  SIGNUP_DESCRIPTION = 'signup-description',
  SIGNUP_BUTTON = 'signup-button',
  SIGNUP_LOGIN = 'signup-login',
  SIGNUP_LOGIN_BUTTON = 'signup-login-button',
  LOGIN_TITLE = 'login-title',
  LOGIN_DESCRIPTION = 'login-description',
  LOGIN_BUTTON = 'login-button',
  LOGIN_SIGNUP = 'login-signup',
  LOGIN_SIGNUP_BUTTON = 'login-signup-button',
  LOGIN_FORGOT_PASSWORD = 'login-forgot-password',
  LOGIN_FORGOT_PASSWORD_BUTTON = 'login-forgot-password-button',
  ADD_PHONE_TITLE = 'add-phone-title',
  ADD_PHONE_DESCRIPTION = 'add-phone-description',
  ADD_PHONE_BUTTON = 'add-phone-button',
  ACCOUNT_LANDING_TITLE = 'account-landing-title',
  ACCOUNT_LANDING_DESCRIPTION = 'account-landing-description',
  NO_ORDERS_TITLE = 'no-orders-title',
  NO_ORDERS_DESCRIPTION = 'no-orders-description',
  NO_ORDERS_BUTTON = 'no-orders-button',
  NO_USER_ORDERS_TITLE = 'no-user-orders-title',
  NO_USER_ORDERS_DESCRIPTION = 'no-user-orders-description',
}

export interface AppCustomPage {
  name: string;
  settings: {
    page: CustomPageStyle;
    title?: string;
  };
}

export interface AppCustomCode {
  type: string;
  url: string;
}

export interface AppNavigationTabBase {
  title: string;
  iconName: string;
}

export interface AppNavigationByAppUrl {
  url: AppModule;
  id?: string;
  venueId?: string;
  venueIds?: string[];
}

export interface AppNavigationByExternalUrl {
  externalUrl: string;
}

export type AppNavigationTab = AppNavigationTabBase &
  (AppNavigationByAppUrl | AppNavigationByExternalUrl);

export type AppNavigationAccountSection = AppNavigationTabBase & {
  url: string;
};

export interface AppPageTitle {
  page: string;
  value: string;
}

export interface AppAuthPage {
  authMethods: AuthMethods[];
  placeholderText?: string;
  loginText?: string;
  signupText?: string;
  requireUsername?: boolean;
}

export interface AppBrand {
  name: string;
  logo: {
    url: string;
  };
}

export interface AppConfig {
  customerAccountScope: string;
  brand: AppBrand;
  home: string;
  auth: AppAuthPage;
  customCode: AppCustomCode[];
  customPages: AppCustomPage[];
  tabs: AppNavigationTab[];
  classifications: string[];
  titles: AppPageTitle[];
  accountMenu: AppNavigationAccountSection[];
}

export interface CustomString {
  key: CustomStringKey;
  value: string;
}

export class ConfigService implements AppConfig {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public ENV = 'demo';

  public customerAccountScope = 'partake';

  public whiteLabelId: string;

  public whiteLabelName: string;

  public brand: AppBrand;

  public auth: AppAuthPage = {
    authMethods: [AuthMethods.EMAIL_PASSWORD],
    requireUsername: false,
  };

  public customStrings: CustomString[] = [];

  public customCode: AppCustomCode[] = [];

  public customPages: AppCustomPage[] = [
    {
      name: 'fantasy',
      settings: {
        page: CustomPageStyle.BRANDED,
      },
    },
    {
      name: 'live',
      settings: {
        page: CustomPageStyle.BRANDED,
      },
    },
  ];

  public tabs: AppNavigationTab[] = [
    {
      title: 'Home',
      iconName: 'home-outline',
      url: AppModule.DISCOVER,
    },
    {
      title: 'Search',
      iconName: 'search-outline',
      url: AppModule.SEARCH,
    },
    {
      title: 'Live',
      iconName: 'play-circle-outline',
      url: AppModule.LIVE,
    },
    {
      title: 'Favorites',
      iconName: 'heart-outline',
      url: AppModule.FAVORITES,
    },
    {
      title: 'My Account',
      iconName: 'person-outline',
      url: AppModule.ACCOUNT,
    },
  ];

  public classifications: string[] = [];

  public titles: AppPageTitle[] = [];
  public home = '/discover';

  public accountMenu = [
    {
      url: '/app/account/profile',
      iconName: 'person-outline',
      title: 'My Profile',
    },
    {
      url: '/app/account/payment',
      iconName: 'card-outline',
      title: 'Payments',
    },
    {
      url: '/app/account/balances',
      iconName: 'cash-outline',
      title: 'Account Balances',
    },
    {
      url: '/app/account/orders',
      iconName: 'time-outline',
      title: 'Order History',
    },
    {
      url: '/app/account/fantasy/history',
      iconName: 'game-controller-outline',
      title: 'Game History',
    },
    {
      url: '/app/account/settings',
      iconName: 'settings-outline',
      title: 'Settings',
    },
  ];

  public getTabForAppUrl(url: string): AppNavigationTab {
    return this.tabs.find(t => {
      return (t as AppNavigationByAppUrl).url === url;
    });
  }

  private defaultStrings: Record<CustomStringKey, string> = {
    [CustomStringKey.SIGNUP_TITLE]: 'Sign Up',
    [CustomStringKey.SIGNUP_DESCRIPTION]:
      'Enter your info to begin enjoying the experiences on offer.',
    [CustomStringKey.SIGNUP_BUTTON]: 'Create Account',
    [CustomStringKey.SIGNUP_LOGIN]: 'Already have an account?',
    [CustomStringKey.SIGNUP_LOGIN_BUTTON]: 'Sign In',
    [CustomStringKey.LOGIN_TITLE]: 'Sign In',
    [CustomStringKey.LOGIN_DESCRIPTION]: 'Just one step left!',
    [CustomStringKey.LOGIN_BUTTON]: 'Sign In',
    [CustomStringKey.LOGIN_SIGNUP]: "Don't have an account?",
    [CustomStringKey.LOGIN_SIGNUP_BUTTON]: 'Sign Up',
    [CustomStringKey.LOGIN_FORGOT_PASSWORD]: 'Forgot Password?',
    [CustomStringKey.LOGIN_FORGOT_PASSWORD_BUTTON]: 'Reset Password',
    [CustomStringKey.ADD_PHONE_TITLE]: 'add-phone-title',
    [CustomStringKey.ADD_PHONE_DESCRIPTION]:
      'Add your phone number so you can be kept up to date on the status of your order. A verified phone is required to place an order.',
    [CustomStringKey.ADD_PHONE_BUTTON]: 'Add Phone Number',
    [CustomStringKey.ACCOUNT_LANDING_TITLE]: 'Sign in and start ordering.',
    [CustomStringKey.ACCOUNT_LANDING_DESCRIPTION]: 'Order food, get vouchers and much more!',
    [CustomStringKey.NO_ORDERS_TITLE]: "You haven't got any open orders.",
    [CustomStringKey.NO_ORDERS_DESCRIPTION]: 'Order ahead and save time.',
    [CustomStringKey.NO_ORDERS_BUTTON]: 'Search Now',
    [CustomStringKey.NO_USER_ORDERS_TITLE]: 'Login to Start Ordering',
    [CustomStringKey.NO_USER_ORDERS_DESCRIPTION]: 'Go to My Account to login.',
  };

  public getCustomString(key: CustomStringKey): string {
    const customString = this.customStrings.find(cs => cs.key === key);
    return customString ? customString.value : this.defaultStrings[key];
  }
}
