import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import pkg from '../package.json';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppInjector } from './app/app-injector.service';

const env = window['env'] || 'dev';

if (env === 'prod') {
  Sentry.init({
    dsn: 'https://94e274e1522840d0b71e6fdef78497de@o447922.ingest.sentry.io/5428456',
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: ['/^//'],
    release: `v${pkg.version}`,
    tracesSampleRate: 1.0,
    environment: env || 'dev',
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Payments
      /^paypay\.com/i,
      /^stripe\.com/i,
      // Google
      /\/(gtm|ga|analytics)\.js/i,
    ],
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(moduleRef => {
    AppInjector.setInjector(moduleRef.injector);
  })
  .catch(err => console.log(err));
