import moment from 'moment';
import _ from 'lodash';
import { DiningOption, OrderDestination } from '@app/models';
import { DiningOptionsBehavior, DiningOptionsOrderType } from '@app/enums';
import * as LocalStorage from './local-storage.helper';

export class OrderDestinationHelper {
  private static KEY = 'order-destination';

  public static async setOrderDestination(menu: string, destination?: OrderDestination) {
    if (destination) {
      const dest = { ...destination, updated: new Date() };
      await LocalStorage.addObject(`${OrderDestinationHelper.KEY}-${menu}`, dest);
    } else {
      await LocalStorage.removeItem(`${OrderDestinationHelper.KEY}-${menu}`);
    }
  }

  public static async getOrderDestination(menu: string): Promise<OrderDestination> {
    try {
      const destination = await LocalStorage.getObject(`${OrderDestinationHelper.KEY}-${menu}`);
      if (!destination) {
        return null;
      }
      const now = moment().format('YYYY-MM-DD');
      const added = moment(destination.updated).format('YYYY-MM-DD');
      if (now !== added) {
        await OrderDestinationHelper.setOrderDestination(menu);
        return null;
      }
      return destination;
    } catch (e) {
      // do nothing
      return null;
    }
  }

  public static isActive(destination: OrderDestination, diningOptions: DiningOption[]): boolean {
    return !!diningOptions.find(diningOption => {
      return diningOption.id === destination?.diningOptionId;
    });
  }

  /**
   * @param destination
   * @param diningOption
   */
  public static isValid(destination: OrderDestination, rawOrderDestination: any): boolean {
    if (!destination?.orderType || !destination.behaviour) {
      return false;
    }
    if (destination?.orderType === DiningOptionsOrderType.FUTURE) {
      if (!destination?.selectedDate) {
        return false;
      }
      if (!rawOrderDestination?.schedule?.skipTimeRequirement && !destination?.selectedTime) {
        return false;
      }
    }

    if (destination?.behaviour === DiningOptionsBehavior.PICKUP) {
      if (!destination?.location?.option1) {
        return false;
      }
    }
    if (destination?.behaviour === DiningOptionsBehavior.DELIVERY) {
      if (!destination?.location?.option1) {
        return false;
      }
      if (
        _.size(rawOrderDestination.location.option1?.options) &&
        !destination?.location?.option2
      ) {
        return false;
      }
      if (
        _.size(rawOrderDestination.location.option2?.options) &&
        !destination?.location?.option3
      ) {
        return false;
      }
      if (
        _.size(rawOrderDestination.location.option3?.options) &&
        !destination?.location?.option4
      ) {
        return false;
      }
    }
    return true;
  }
}
