import { TransactionCardScheme } from '../enums';

export default class PaymentHelper {
  static getStripeCardSchemeFromStripeCard(stripeCard: any): string {
    const brand = stripeCard?.brand?.toLowerCase();
    switch (brand) {
      case 'amex':
      case 'american express':
        return TransactionCardScheme.AMEX;
      case 'visa':
        return TransactionCardScheme.VISA;
      case 'mastercard':
        return TransactionCardScheme.MASTERCARD;
      case 'discover':
        return TransactionCardScheme.DISCOVER;
      case 'diners':
        return TransactionCardScheme.DINERS;
      case 'maestro':
        return TransactionCardScheme.MAESTRO;
      case 'jcb':
        return TransactionCardScheme.JCB;
      default:
        return TransactionCardScheme.OTHER;
    }
  }
}
