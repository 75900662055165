export enum ApiResources {
  AUTH = 'auth',
  ATTRACTIONS = 'attractions',
  ARENAS = 'arena',
  BALANCE = 'balance',
  BALANCE_TRANSACTION = 'balance-transaction',
  CLASSIFICATIONS = 'classifications',
  COURSES = 'course',
  DINING_OPTIONS = 'dining-options',
  EVENTS = 'events',
  FAVORITES = 'favorite',
  FOLLOWER = 'follower',
  GAMES = 'game',
  GAME_ENTRIES = 'game-entry',
  HEADS_UP = 'heads-up',
  HEADS_UP_ENTRY = 'heads-up-entry',
  HELP = 'help',
  IMAGES = 'images',
  MATCHES = 'match',
  MENU = 'menu',
  MENU_ITEMS = 'menu-item',
  MENU_ITEM_MODIFIER = 'menu-item-modifier',
  MORE_LESS = 'more-less',
  MORE_LESS_ENTRY = 'more-less-entry',
  ORDERS = 'order',
  PAYMENT_METHOD = 'payment-method',
  PICK_EMS = 'pick-em',
  POST = 'post',
  PRIZES = 'prizes',
  PROFILE = 'profile',
  PROMOTIONS = 'promotions',
  PAYMENT = 'payment',
  PROPS = 'props',
  PROPS_ENTRY = 'props-entry',
  PRICING_CONFIGS = 'pricing-config',
  RESTAURANTS = 'restaurant',
  STRIPE = 'stripe',
  TENDER_MAPS = 'tender-map',
  TOPIC = 'topic',
  TRIVIA = 'trivia',
  TRIVIA_ENTRY = 'trivia-entry',
  VENUES = 'venue',
  VOUCHER = 'voucher',
  USER = 'user',
  /** @deprecated */
  USERS = 'users',
}
