export enum ProfileType {
  USER = 'user',
  GROUP = 'group',
  PAGE = 'page',
}

export enum ProfileVisibility {
  PUBLIC = 'public',
  PRIVATE = 'private',
}
