import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import * as moment from 'moment';

@Pipe({
  name: 'orderTime',
})
export class OrderTimePipe implements PipeTransform {
  constructor(
    private _sanitizer: DomSanitizer,
    private _datePipe: DatePipe
  ) {}

  transform(value: string): SafeHtml {
    if (value) {
      const date = new Date(value);
      const min = moment(date).subtract(5, 'minutes').toDate();
      const max = moment(date).add(5, 'minutes').toDate();
      return this._sanitizer.bypassSecurityTrustHtml(
        `<span>${this._datePipe.transform(min, 'hh:mm a')}</span> to<br>
        <span>${this._datePipe.transform(max, 'hh:mm a')}</span`
      );
    }
    return '';
  }
}
