import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { CheckoutModule } from '@app/checkout/checkout.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { PipesModule } from '../pipes/pipes.module';
import { SkeletonCardsComponent } from './skeleton-cards/skeleton-cards.component';
import { SkeletonListItemsComponent } from './skeleton-list-items/skeleton-list-items.component';
import { CartButtonComponent } from './cart-button/cart-button.component';
import { OrderOpenComponent } from './order-open/order-open.component';
import { SpecialInstructionsModalComponent } from './special-instructions-modal/special-instructions-modal.component';
import { ImageCropperModalComponent } from './modals/image-cropper-modal/image-cropper-modal.component';
import { CountdownComponent } from './countdown/countdown.component';

import { AutocompleteComponent } from './modals/autocomplete/autocomplete.component';
import { ModalComponent } from './modals/modal/modal.component';
import { DepositModalComponent } from './modals/deposit-modal/deposit-modal.component';
import { AlertModalComponent } from './modals/alert-modal/alert-modal.component';
import { ShareModalComponent } from './modals/share-modal/share-modal.component';
import { BrandedHeaderComponent } from './branded-header/branded-header.component';
import { PagerComponent } from './pager/pager.component';

@NgModule({
  declarations: [
    SkeletonCardsComponent,
    SkeletonListItemsComponent,
    CartButtonComponent,
    OrderOpenComponent,
    SpecialInstructionsModalComponent,
    ImageCropperModalComponent,
    AutocompleteComponent,
    CountdownComponent,
    ModalComponent,
    AlertModalComponent,
    DepositModalComponent,
    ShareModalComponent,
    BrandedHeaderComponent,
    PagerComponent,
  ],
  exports: [
    SkeletonCardsComponent,
    SkeletonListItemsComponent,
    CartButtonComponent,
    OrderOpenComponent,
    SpecialInstructionsModalComponent,
    ImageCropperModalComponent,
    AutocompleteComponent,
    CountdownComponent,
    BrandedHeaderComponent,
    PagerComponent,
    AlertModalComponent,
    DepositModalComponent,
    ShareModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CheckoutModule,
    ImageCropperComponent,
    ShareButtonsModule,
    ShareIconsModule,
    PipesModule,
    FontAwesomeModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
