import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import _ from 'lodash';
import { ImageSize } from '../../../enums';
import { ImageHelper } from '../../../helpers';
import { ICustomerEducation, ICustomerEducationContent } from '../../../models';

@Component({
  selector: 'app-education-modal',
  templateUrl: './education-modal.component.html',
  styleUrls: ['./education-modal.component.scss'],
})
export class EducationModalComponent implements OnInit {
  @Input() steps: Array<ICustomerEducation>;
  public step = 0;

  public imageUrl: string;
  public sections: Array<{ title?: string; content: string }> = [];

  constructor(private modal: ModalController) {}

  ngOnInit() {
    this.setupStep();
  }

  private setupStep() {
    const data = this.steps[this.step];
    this.imageUrl = ImageHelper.getImage(data, ImageSize.FULL);
    this.sections = _.map(data?.content, (content: ICustomerEducationContent) => ({
      title: content.sectionTitle,
      content: content.sectionText,
    }));
  }

  public skip() {
    this.modal.dismiss({ skip: true });
  }

  public next() {
    if (this.step < _.size(this.steps) - 1) {
      this.step += 1;
      this.setupStep();
    } else {
      this.modal.dismiss({ next: true });
    }
  }

  public prev() {
    if (this.step > 0) {
      this.step -= 1;
      this.setupStep();
    }
  }
}
