import { SoccerPeriod } from '../enums';

export class SportsDataHelper {
  public static SHOW_TIME = [
    SoccerPeriod.FIRST_HALF,
    SoccerPeriod.SECOND_HALF,
    SoccerPeriod.EXTRA_TIME_FIRST_HALF,
    SoccerPeriod.EXTRA_TIME_SECOND_HALF,
  ];

  static soccerPeriodIdToString(id: number) {
    switch (id) {
      case SoccerPeriod.FIRST_HALF:
        return 'H1';
      case SoccerPeriod.SECOND_HALF:
        return 'H2';
      case SoccerPeriod.EXTRA_TIME_FIRST_HALF:
        return 'ET H1';
      case SoccerPeriod.EXTRA_TIME_SECOND_HALF:
        return 'ET H2';
      case SoccerPeriod.PENALTY_SHOOTOUT:
        return 'Penalties';
      case SoccerPeriod.HALF_TIME:
        return 'HT';
      case SoccerPeriod.END_OF_SECOND_HALF:
        return 'End H2';
      case SoccerPeriod.EXTRA_TIME_HALF_TIME:
        return 'ET HT';
      case SoccerPeriod.END_OF_EXTRA_TIME:
        return 'End ET';
      case SoccerPeriod.FULL_TIME:
        return 'FT';
      case SoccerPeriod.PRE_MATCH:
        return 'Pre-Match';
      default:
        return '';
    }
  }

  static periodNormalTime(id: number) {
    switch (id) {
      case SoccerPeriod.FIRST_HALF:
        return 45;
      case SoccerPeriod.SECOND_HALF:
        return 90;
      case SoccerPeriod.EXTRA_TIME_FIRST_HALF:
        return 105;
      case SoccerPeriod.EXTRA_TIME_SECOND_HALF:
        return 120;
      case SoccerPeriod.PENALTY_SHOOTOUT:
      case SoccerPeriod.HALF_TIME:
      case SoccerPeriod.END_OF_SECOND_HALF:
      case SoccerPeriod.EXTRA_TIME_HALF_TIME:
      case SoccerPeriod.END_OF_EXTRA_TIME:
      case SoccerPeriod.FULL_TIME:
      case SoccerPeriod.PRE_MATCH:
        return 0;
      default:
        return 0;
    }
  }
}
