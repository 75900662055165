import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  NgZone,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ModalController } from '@ionic/angular';

export interface FooterButtons {
  start?: FooterButton[];
  end?: FooterButton[];
}

export interface FooterButton {
  label: string;
  action: (...args: any[]) => void;
  color?: string;
  size?: 'small' | 'default' | 'large';
  fill?: 'clear' | 'outline' | 'solid';
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements AfterViewInit {
  @Input() title: string;
  @Input() component: any;
  @Input() componentProps: any;
  @Input() hideFooter = false;
  @Input() buttons: {
    skip: { title: string; size: string };
    next: { title: string; size: string };
  };
  @Input() footerButtons: FooterButtons;
  @Input() next: (...args: any[]) => any;
  @Input() skip: (...args: any[]) => any;

  @ViewChild('content', { read: ViewContainerRef })
  parent: ViewContainerRef;

  private data: any;

  public enabled = false;

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _cd: ChangeDetectorRef,
    private _zone: NgZone,
    private _modalCtrl: ModalController
  ) {}

  ngAfterViewInit() {
    return this._zone.run(
      () =>
        new Promise(resolve => {
          const childComponent = this._componentFactoryResolver.resolveComponentFactory(
            this.component
          );
          const componentRef = this.parent.createComponent(childComponent);
          if (this.componentProps) {
            Object.assign(componentRef.instance, this.componentProps);
          }
          this._cd.detectChanges();
          // eslint-disable-next-line dot-notation
          if (componentRef.instance['output']) {
            // eslint-disable-next-line dot-notation
            componentRef.instance['output'].subscribe(data => {
              this.data = data;
            });
          }
          resolve(componentRef);
        })
    );
  }

  public close() {
    this._modalCtrl.dismiss();
  }

  public takeAction(btn: FooterButton) {
    btn.action(this.data);
  }

  public action(skip: boolean) {
    if (skip) {
      if (this.skip) {
        this.skip();
      } else {
        this.close();
      }
    } else if (this.next) {
      this.next(this.data);
    } else {
      this._modalCtrl.dismiss(this.data || true);
    }
  }
}
