import _ from 'lodash';

export enum Widget {
  SELECT = 'select',
  LONG_STRING = 'textarea',
}

export interface IWidget {
  type: Widget;
  inputType?: string;
  lines?: number;
  options?: any;
}

export enum SchemaType {
  BOOLEAN = 'boolean',
  EMAIL = 'email',
  NUMBER = 'number',
  TEL = 'tel',
  PASSWORD = 'password',
  STRING = 'string',
  LONG_STRING = 'longString',
  OBJECT = 'object',
  ARRAY = 'array',
}

export type ComparitorFunc = (value: any) => boolean;
export type ValidatorFunc = (value: any) => boolean;

export interface ComparitorOptions {
  field: string;
  shouldShow: ComparitorFunc;
}

export interface IProperties {
  section: string;
  type: SchemaType;
  label?: string;
  items?: IProperties;
  properties?: ISchema;
  description?: string;
  placeholder?: string;
  widget?: IWidget;
  default?: any;
  required?: boolean;
  immutable?: boolean;
  enum?: Array<{ key: string; name: any }>;
  multiple?: boolean;
  onlyIf?: string | ComparitorOptions[];
  notIf?: string | ComparitorOptions[];
  reference?: string;
  isHidden?: boolean;
  validators?: ValidatorFunc[];
  validationErrorMessage?: string;
  maxlength?: number;
  minlength?: number;
}

export interface ISchema {
  [key: string]: IProperties;
}

export function validateWithSchema<T>(
  schema: ISchema,
  data: T
): { errors: string[]; isValid: boolean } {
  const result = { errors: [], isValid: true };
  Object.keys(schema).forEach((key: string) => {
    const property: IProperties = schema[key];
    if (property.required) {
      if (!data[key]) {
        result.isValid = false;
        result.errors.push(`${property.label} is required`);
      }
    }
    if (data[key] && _.size(property.validators)) {
      let fieldIsValid = true;
      property.validators.forEach((validator: ValidatorFunc) => {
        const isValid = validator(data[key]);
        if (!isValid) {
          fieldIsValid = false;
        }
      });
      if (!fieldIsValid) {
        result.isValid = false;
        result.errors.push(property.validationErrorMessage || `${property.label} is not valid.`);
      }
    }
  });
  return result;
}
