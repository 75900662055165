import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(address: any): string {
    if (!address) {
      return '';
    }
    if (address.formatted) {
      return address.formatted;
    }
    const parts = [
      address.line1,
      address.line2,
      address.city,
      `${address.state ? `${address.state} ` : ''}`,
      address.zip,
    ];
    return parts.filter(x => x).join(', ');
  }
}
