/* eslint-disable no-param-reassign */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concession',
})
export class ConcessionPipe implements PipeTransform {
  transform(concessions: Array<any>, arena?: any, location?: any): Array<any> {
    const filtered: Array<any> = [];

    if (!arena || !location || !location.deliveryOptions) {
      return concessions;
    }

    // We only have a service option here and no other options
    if (location.deliveryOptions && !location.section) {
      concessions.forEach(x => {
        if (x.deliveryOptions[location.deliveryOptions]) {
          filtered.push(x);
        }
      });
    } else if (location.section) {
      const needsRow = location.row && location.deliveryOptions === 'delivery';

      arena.sections = arena.sections.map(x => {
        x.restaurants = x.restaurants.map(y => {
          if (y._id) {
            return y._id;
          }
          return y;
        });
        return x;
      });

      const section = arena.sections.find(x => x.name === location.section);
      let row: any;

      if (section && location.row) {
        row = section.rows.find(x => x.name === location.row);
        row.restaurants = row.restaurants.map(x => {
          if (x._id) {
            return x._id;
          }
          return x;
        });
      }

      concessions.forEach(concession => {
        const inSection = section && section.restaurants.indexOf(concession._id) > -1;
        const inRow =
          row && row.restaurants.length && needsRow
            ? row.restaurants.indexOf(concession._id) > -1
            : true;

        const hasPickup =
          concession.deliveryOptions.pickup &&
          section &&
          section.deliveryOptions.pickup &&
          inSection;
        let hasDelivery =
          concession.deliveryOptions.delivery &&
          section &&
          section.deliveryOptions.delivery &&
          inSection;

        // We need to update the delivery if a row has been picked
        if (inSection && needsRow) {
          hasDelivery =
            concession.deliveryOptions.delivery &&
            section.deliveryOptions.delivery &&
            inRow &&
            row.deliveryOptions.delivery;
        }

        if (location.deliveryOptions === 'delivery' && hasDelivery) {
          filtered.push(concession);
        } else if (location.deliveryOptions === 'pickup' && hasPickup) {
          filtered.push(concession);
        }
      });
    }

    return filtered;
  }
}
