export enum FulfillmentMode {
  NONE = 'none',
  LOCATION = 'location',
  STATION = 'station',
  LOCATION_STATION = 'location-station',
}

export enum InventoryMode {
  NONE = 'none',
  LOCATION = 'location',
  STAND_SHEET = 'stand-sheet',
}

export enum LocationType {
  RESTAURANT = 'restaurant',
  PRO_SHOP = 'pro-shop',
  STORE = 'store',
  STORAGE = 'storage',
}
